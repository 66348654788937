import { defineStore } from "pinia";
import { useCustomerCartStore } from "@/views/pos/stores/customer";
import { usePaymentsStore } from "@/views/pos/stores/payments"
import { usePOSStore } from "./pos";
import { useAppStore } from "@/stores/app";
import useHelpers from '@/views/pos/composables/useHelpers';
import posConfig from "@/views/pos/config/pos"
import ApiService from "@/core/services/apiServicev3";
import { useProductStore } from "@/stores/products";
import generateUniqueId from "@/composables/v3/components/helpers/useGenerateUniqueId";

interface RootState {
  sale: any
  lines: any
  totals: any
  taskQueue: any
  isProcessingQueue: boolean
  searchResults: any
  searchOptions: any
  scannerSearch: boolean
  isLoading: boolean
  payable: boolean
  isCompleted: boolean
  paymentMethod: string | null
  searchValue: string | null
  renderAmount: number
  isSynched: boolean
}

let searchTimeout;

export const useCartStore = defineStore({
  id: "cartStore",
  state: () =>
  ({
    sale: null,
    lines: [],
    totals: {
      subtotalExclVat: 0,
      discountTotalExclVat: 0,
      totalExclVat: 0,
      totalVat: 0,
      totalInclVat: 0,
      outstandingAmount: 0
    },
    taskQueue: [],
    isProcessingQueue: false,
    isSynched: false,
    searchResults: null,
    searchOptions: [],
    scannerSearch: false,
    isLoading: true,
    payable: false,
    isCompleted: false,
    paymentMethod: null,
    searchValue: '',
    renderAmount: 0,
  } as RootState),
  getters: {
    isSaleLoaded(state) {
      return state.sale != null ? true : false
    },
    saleProfileId(state) {
      return state.sale?.profile_id;
    },
    getSearchResults(state) {
      return state.searchResults;
    },
    amountSaleLines(state) {
      return state.sale?.sales_lines.length
    },
    getLoadingStatus(state) {
      return state.isLoading;
    },
    getCartLines(state) {
      return state.sale?.sales_lines;
    },
    getSale(state) {
      return state.sale;
    },
    // isPayable(state) {
    //   return state.payable;
    // },
    selectedPaymentMethod(state) {
      return state.paymentMethod;
    },
    getSearchValue(state) {
      return state.searchValue;
    },
    paidAmount(state) {
      return (state.totals.totalInclVat - state.totals.outstandingAmount);
    },
    outstandingAmount(state) {
      return state.totals.outstandingAmount;
    },
    totalAmount(state) {
      return state.sale?.totals.net_total_with_vat;
    },
    saleId(state) {
      return state.sale?.id;
    },
    zeroAmountLines(state) {
      return state.sale?.sales_lines.filter(line => line.price == "0.00");
    },
    saleStatus(state) {
      return state.sale?.sale_status;
    },
    cartLines(state) {
      return state.lines;
    },
    saleCartLines(state) {
      return state.sale?.sales_lines;
    },
    totalCartLines(state) {
      return state.lines.length
    },
    isSaleCompleted(state) {
      return state.sale?.sale_status === 'completed' || state.isCompleted ? true : false;
    }
  },
  actions: {
    setPaymentMethod(method: string) {
      this.paymentMethod = method;
    },
    setOutstandingStatus() {
      let count = 0
      for (const item of this.getCartLines) {
        if (Number(item.quantity) > Number(item.billed_quantity)) {
          count++
        }
      }
      this.payable = true;
      // this.outstandingAmount === "0.00" || this.paidAmount === this.totalAmount && count == 0 ? this.payable = false : this.payable = true;
    },
    searchProduct(query: string, cb: (arg: any) => void) {
      const appStore = useAppStore();
      this.searchValue = query;

      // Clear the previous timeout if it exists
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }

      if (this.searchValue.length) {
        // Set a new timeout to delay the API call
        searchTimeout = setTimeout(async () => {
          try {
            const payload = {
              q: this.searchValue,
              sort: ['id:desc'],
              matchingStrategy: 'all',
              facets: ['*'],
              offset: 0,
            };

            const { data: searchResults } = await ApiService.post('apiUrlSearch', '/indexes/products/search', payload)

            this.searchResults = this.createSearchResults(searchResults.hits);
            await this.searchList();
            cb(this.searchOptions);

            if (this.searchResults.length === 1 && this.scannerSearch) {
              const item = this.searchResults[0];
              // await this.addItem(item.id);
              this.addItem(item);
              // this.searchValue = null;
              this.scannerSearch = false;
              this.renderAmount += 1;
              // nextTick(() => {
              //   const searchBox = document.getElementById(
              //     'searchProduct'
              //   ) as HTMLInputElement | null;
              //   searchBox!.value = '';
              //   searchBox!.focus();
              // });
              this.searchOptions = [];
              cb(this.searchOptions);
            }
          } catch (error) {
            // Handle errors here
            console.error('Error:', error);
          }
        }, 300); // Adjust the delay time as needed
      } else {
        this.searchOptions = [];
      }
    },
    createSearchResults(items) {
      const results = [] as any;

      for (const item of items) {
        const template = {
          id: item.id,
          title: item.title,
          media: item.media,
          price: item.price,
          prices: {
            default: item.price,
          },
          discountPercentage: item.customer_price.discount_percentage,
          vatPercentage: item.vat_percentage,
          vatIncluded: item.vat_included === 1 ? true : false
        }

        results.push(template)
      }

      return results
    },
    async processSelectProductSearch(item) {
      const product = this.searchResults.find(product => product.id === item.id);

      console.log(product)

      await this.addItem(product);
    },
    async addItem(item, method?) {
      this.searchValue = '';

      const { id: productId } = item;

      if (productId) {
        this.isLoading = true;

        const cartItems = this.lines;
        const exists = cartItems.find(item => item.id === productId);

        const task = await this.createTask(null, 'concept', null, null, productId, null);

        if (exists) {

          if (!exists.isLocked) {
            const newQuantity = method === 'inline' ? exists.quantity : exists.quantity += 1;
            exists.quantity = newQuantity;

            this.updateTask(task.id, { lineId: exists.lineId, type: 'update', quantity: newQuantity, status: 'pending', payload: { quantity: newQuantity } });
            this.calculateLineTotals(exists);

          } else {
            console.log(item)
            const line = await this.formatProductTemplate(item)
            this.cartLines.push(line);

            this.updateTask(task.id, { type: 'add', quantity: 1, status: 'pending' })
          }
        } else {

          const line = await this.formatProductTemplate(item);
          await this.calculateLineTotals(line)
          this.cartLines.push(line);

          this.updateTask(task.id, { type: 'add', quantity: 1, status: 'pending' })
        }

        this.calculateTotals();

        if (!this.isProcessingQueue) {
          await this.processTaskQueue();
        }

        this.isLoading = false;
      }
    },
    async processTaskQueue() {
      this.isProcessingQueue = true;

      try {

        while (this.taskQueue.length > 0) {
          const firstTask = this.taskQueue[0];

          if (firstTask.status === 'concept') {
            await new Promise<void>(resolve => {
              const checkStatus = () => {
                if (firstTask.status === 'pending') {
                  resolve();
                } else {
                  setTimeout(checkStatus, 100); // Controleer elke 10 milliseconden
                }
              };
              checkStatus();
            });
          } else if (firstTask.status === 'pending') {
            await this.processTask(firstTask);

            if (firstTask.status === 'done') {
              this.calculateTotals();
              this.taskQueue.shift();
            }
          }
        }
      } finally {
        this.isProcessingQueue = false;
        this.isLoading = false;
      }
    },
    async processTask(task) {
      const { payload, type, productId, lineId } = task;

      if (type === 'add') {
        const cartItem = this.cartLines.find(line => line.id === productId);

        if (cartItem) {
          const saleLineId = await this.createSaleLine(cartItem);

          if (saleLineId) {
            cartItem.lineId = saleLineId;

            for (const task of this.taskQueue.filter(t => t.productId === productId)) {
              task.lineId = saleLineId;
            }

            task.status = 'done';
          }
        }
      } else if (type === 'update') {
        const cartItem = this.cartLines.find(line => line.lineId === lineId);

        if (cartItem) {
          const line = await this.updateLine(cartItem.lineId, payload);

          if (line) {
            task.status = 'done';
          }
        }
      } else if (type === 'delete') {
        const cartItem = this.cartLines.find(line => line.lineId === lineId);

        if (cartItem) {
          const line = await this.deleteLine(cartItem.lineId);

          if (line.status === 'success') {
            this.lines = this.cartLines.filter(line => line.lineId !== cartItem.lineId);
            task.status = 'done';
          }
        }
      }
    },
    async calculateLineTotals(line, product?) {
      const { roundToDecimalPlaces } = useHelpers();

      const unitPrice = product ? product.baseInclVat : line.prices.default;
      const quantity = line.quantity;
      const vatIncluded = line.vatIncluded;
      const vatPercentage = line.vatPercentage;
      const discountPercentage = line.discountPercentage;

      const {
        totalInclVat,
        totalExclVat,
        discountExclVat,
        subtotalExclVat,
        totalVat,
      } = this.calculateLinePriceFormat(unitPrice, quantity, vatIncluded, vatPercentage, discountPercentage);

      line.totals = {
        subtotalExclVat: roundToDecimalPlaces(subtotalExclVat, 2),
        subtotalInclVat: roundToDecimalPlaces(totalInclVat, 2),
        discountExclVat: roundToDecimalPlaces(discountExclVat, 2),
        discountInclVat: roundToDecimalPlaces(discountExclVat, 2),
        totalExclVat: roundToDecimalPlaces(totalExclVat, 2),
        totalVat: roundToDecimalPlaces(totalVat, 2),
        totalInclVat: totalInclVat
      };
    },
    calculateLinePriceFormat(unitPrice, quantity, vatIncluded, vatPercentage, discountPercentage) {
      const { roundToDecimalPlaces } = useHelpers();
      let totalInclVat, totalExclVat, discountExclVat, subtotalExclVat, totalVat;

      if (vatIncluded) {
        totalInclVat = roundToDecimalPlaces((unitPrice * quantity), 2);
        const discountAmount = roundToDecimalPlaces((totalInclVat * (discountPercentage / 100)), 3, null, false, false); // The discount is rounded off in favor of the seller by default.
        totalInclVat = roundToDecimalPlaces((totalInclVat - discountAmount), 2);
        totalExclVat = totalInclVat / (1 + (vatPercentage / 100));
        discountExclVat = totalExclVat * (discountPercentage / 100);
        subtotalExclVat = totalExclVat - discountExclVat;
        totalVat = totalInclVat - totalExclVat;
      } else {
        totalExclVat = roundToDecimalPlaces((unitPrice * quantity), 2);
        const discountAmount = roundToDecimalPlaces((totalExclVat * (discountPercentage / 100)), 2, null, false, false);
        totalExclVat -= discountAmount;
        discountExclVat = totalExclVat * (discountPercentage / 100);
        subtotalExclVat = totalExclVat - discountExclVat;
        totalVat = subtotalExclVat * (vatPercentage / 100);
        totalInclVat = subtotalExclVat + totalVat;
      }

      return {
        totalInclVat,
        totalExclVat,
        discountExclVat,
        subtotalExclVat,
        totalVat,
      };
    },
    async searchList() {
      const list = [] as any
      const promises = Promise.all(
        await this.getSearchResults.map(async (item) => {
          list.push({ value: item.title, id: item.id })
        })
      );
      await promises
      this.searchOptions = list;
    },
    async saleData(uid) {
      const appStore = useAppStore();
      const customerStore = useCustomerCartStore();
      const paymentsStore = usePaymentsStore();
      const posStore = usePOSStore();

      try {
        const document = await appStore.validateDocument(uid, 'sales');
        this.sale = document
        this.setOutstandingStatus();
        await customerStore.getCustomer(this.sale.customer_id);

      }
      catch (error) {
        this.router.push({ path: '/pos/' + posStore.currentPOSId + '/checkout' })
      }
      finally {
        paymentsStore.isReload += 1
      }
    },
    async convertToLanguage(obj, profileLanguage) {
      let convertedText;
      const languageObject = JSON.parse(obj);

      if (profileLanguage in languageObject) {
        convertedText = languageObject[profileLanguage]
      } else {
        convertedText = Object.values(languageObject)[0]
      }

      return convertedText;
    },
    async applyDiscounts(customerId?: number) {
      const productStore = useProductStore();
      const customerStore = useCustomerCartStore();

      const cId = customerId ?? customerStore.currentCustomerId;

      try {
        const cartLines = await this.cartLines;

        const updateTasks = cartLines.map(async (cartLine) => {
          const { id: productId, lineId: saleLineId } = cartLine;
          const { status, data } = await productStore.getProductPrice(productId, cId);

          if (status === 'success') {

            const payload = {
              price: data.baseInclVat,
              discount_percentage: data.discountPercentage
            };

            await this.createTask(saleLineId, 'pending', 'update', null, null, payload);
          }
        });

        await Promise.all(updateTasks);
        await this.processTaskQueue();
        this.initCartSession(this.saleId, false);
      } catch (error) {
        // Handle any errors here, e.g., logging or notifying the user
        console.error("Error in applyDiscounts:", error);
      }
    },
    findProduct(id) {
      const product = this.searchResults.find(product => product.id === id);
      return product
    },
    async itemQuantity(quantity, line) {
      const posStore = usePOSStore();
      // this.isLoading = true;
      try {
        const { lineId, id } = line;
        if (quantity <= 0) {
          // this.lines = this.lines.filter(line => line.id !== id);
          // this.deleteItem(lineId);

          line.quantity = quantity;
          
          await this.createTask(lineId, 'pending', 'delete', 0, null);

          await this.processTaskQueue();

        } else {
          this.addItem(line, 'inline')
          // this.updateLine(lineId, {quantity: quantity})
          // .then(async ( { data }) => {
          //   await posStore.checkTaskStatus(data.task_id);
          // });
        }
      } finally {
        
        this.calculateTotals();
        // await this.saleData(this.sale.id);
        // this.searchValue = null;
        // this.isLoading = false;
      }
    },
    async updateLine(lineId, payload) {
      const posStore = usePOSStore();
      try {
        const { data } = await ApiService.put('apiUrl', `/sales/line/${lineId}`, payload);
        await posStore.checkTaskStatus(data.task_id);

        return data;
      }
      finally {
        //
      }
    },
    async deleteLine(lineId: number) {
      const appStore = useAppStore();

      const response = {
        status: 'error'
      }

      try {
        const { data } = await ApiService.delete('apiUrl', '/sales/lines', { id: [lineId] });
        appStore.taskStatus(data.result.task_id);
        // await posStore.checkTaskStatus(data.result.task_id);
        response.status = 'success';
      }
      catch {
        //
      }

      return response
    },
    createUniqueTaskID() {
      const timestamp = new Date().getTime();
      const randomNumber = Math.floor(Math.random() * 1000000); // Adjust the range as needed
      const taskID = `${timestamp}-${randomNumber}`;
      return taskID;
    },
    // async deleteItem(lineId) {
    //   const posStore = usePOSStore();
    //   try {
    //     const { data } = await ApiService.delete('apiUrl', '/sales/lines', { id: [lineId] });
    //     // await posStore.checkTaskStatus(data.result.task_id);
    //   }
    //   catch {
    //     //
    //   }
    // },
    async updateSale(payload) {
      try {
        const { data } = await ApiService.put('apiUrl', "/sale/" + this.saleId, payload);

        return data;
      }
      finally {
        //
      }
    },
    async initCartSession(saleId, reload?) {
      const paymentStore = usePaymentsStore();
      const posStore = usePOSStore();

      await this.saleData(saleId);
      await paymentStore.allPayments();

      if (!reload) {
        this.initCartItems();
      }

      await this.calculateTotals();
      posStore.createPaymentMethods();

    },
    initCartItems() {
      const { roundToDecimalPlaces } = useHelpers();
      const posStore = usePOSStore();

      this.lines = [] as any;
      const lines = this.saleCartLines;
      for (const line of lines) {
        const unitPrice = line.price;
        const quantity = line.quantity;
        const vatIncluded = line.vat_included === 1 ? true : false;
        const vatPercentage = line.vat_percentage;
        const discountPercentage = line.discount_percentage;

        const {
          totalInclVat,
          totalExclVat,
          discountExclVat,
          subtotalExclVat,
          totalVat,
        } = this.calculateLinePriceFormat(unitPrice, quantity, vatIncluded, vatPercentage, discountPercentage);

        const template = {
          id: line.product_id,
          lineId: line.id,
          title: line.name,
          image: line.media.find(file => file.media_type === 'image') || null,
          price: line.net_total_with_vat / line.quantity,
          prices: {
            default: unitPrice,
          },
          discountPercentage: discountPercentage,
          quantity: quantity,
          vatPercentage: vatPercentage,
          vatIncluded: vatIncluded,
          totals: {
            subtotalExclVat: roundToDecimalPlaces(subtotalExclVat, 2),
            subtotalInclVat: roundToDecimalPlaces(totalInclVat, 2),
            discountExclVat: roundToDecimalPlaces(discountExclVat, 2),
            discountInclVat: '',
            totalExclVat: roundToDecimalPlaces(totalExclVat, 2),
            totalVat: roundToDecimalPlaces(totalVat, 2),
            totalInclVat: roundToDecimalPlaces(totalInclVat, 2)
          },
          isLocked: line.billed_quantity > "0.00",
          isLoading: false
        }

        this.lines.push(template)
      }

      this.calculateTotals();
    },
    
    async calculateTotals() {
      const { roundToDecimalPlaces } = useHelpers();
      const paymentStore = usePaymentsStore();
      await paymentStore.allPayments();

      const paidAmountPayments = paymentStore.payments.reduce((total, payment) => {
        if (payment.payment_status === 'paid') {
          return total + parseFloat(payment.amount_total);
        }
        return total;
      }, 0);
      const outstandingAmountPayments = this.sale.due_amount === "0.00" ? paymentStore.payments.reduce((total, payment) => total += (payment.amount_total), 0) : paymentStore.payments.reduce((total, payment) => total += (Number(payment.amount_outstanding)), 0)

      let subtotalExclVat = 0;
      let discountTotalExclVat = 0;
      let totalExclVat = 0;
      let totalVat = 0;
      let totalInclVat = 0;

      this.lines.forEach((line) => {
        subtotalExclVat += line.totals.subtotalExclVat;
        discountTotalExclVat += line.totals.discountExclVat;
        totalExclVat += line.totals.totalExclVat;
        totalVat += line.totals.totalVat;
        totalInclVat += line.totals.totalInclVat;
      });

      this.totals.subtotalExclVat = roundToDecimalPlaces(subtotalExclVat)
      this.totals.discountTotalExclVat = roundToDecimalPlaces(discountTotalExclVat)
      this.totals.totalExclVat = roundToDecimalPlaces(totalExclVat)
      this.totals.totalVat = roundToDecimalPlaces(totalVat)
      this.totals.totalInclVat = roundToDecimalPlaces(totalInclVat)
      this.totals.outstandingAmount = roundToDecimalPlaces((totalInclVat - paidAmountPayments));
      this.totals.paidAmount = roundToDecimalPlaces(paidAmountPayments)
    },
    async createSaleLine(line: any) {
      const appStore = useAppStore();
      const payload = {
        product_id: line.id,
        name: line.title,
        price: line.prices.default,
        discount_percentage: line.discountPercentage,
        quantity: 1,
        sale_id: this.saleId,
        vat_percentage: line.vatPercentage
      }

      try {
        const { data } = await ApiService.post('apiUrl', 'sales/line', payload);

        await appStore.validateDocument(data.item_id, 'sales_lines');

        return data.item_id;
      }
      catch {
        //
      }
    },
    async processPay() {

      const paymentStore = usePaymentsStore();
      const posStore = usePOSStore();
      paymentStore.$reset();
      paymentStore.isSynching = true;

      setTimeout(async () => {

        //paymentsStore.$reset();
        this.isSynched = false;

        // Function to check if taskQueue is empty
        const waitForTaskQueueEmpty = (): Promise<void> => {
          return new Promise<void>((resolve) => {
            const checkTaskQueue = () => {
              if (this.taskQueue.length === 0) {
                resolve();
              } else {
                setTimeout(checkTaskQueue, 100); // Adjust the delay as needed
              }
            };

            checkTaskQueue();
          });
        };

        // Check if taskQueue is empty before proceeding
        while (this.taskQueue.length > 0) {
          await waitForTaskQueueEmpty();
        }

        try {
          await this.checkSale(this.saleId, this.totals.outstandingAmount);

          if (this.paymentMethod === 'pin') {
            paymentStore.paymentValue = this.totals.outstandingAmount;
          }

          posStore.createPaymentActions();
        }
        catch {
          // TODO:
        }
        finally {
          paymentStore.isSynching = false;
        }
      }, 200)
    },
    async sales(id) {
      const appStore = useAppStore();
      try {
        const { data } = await ApiService.get('apiUrlSearch', `/indexes/sales/documents/${id}`)
        return data;
      }
      catch (error) {
        // this.router.push({ path: '/pos/' + posStore.currentPOSId + '/checkout' })
      }
    },

    async checkSale(saleId: number, outstandingAmount: number): Promise<void> {
      const paymentStore = usePaymentsStore();
      const appStore = useAppStore();
      let amountChecks = 0;
      return new Promise<void>((resolve, reject) => {
        const interval = setInterval(async () => {
          try {
            const response = await ApiService.get('apiUrlSearch', `/indexes/sales/documents/${saleId}`);
            const { data } = response;

            const dueAmount = Number(data.due_amount);
            const tolerance = 0.01; // There is a possibility that differences of 1 cent will arise. This has to do with the method of rounding. We ignore this.

            if (Math.abs(dueAmount - outstandingAmount) <= tolerance) {
              // paymentStore.paymentValue = dueAmount;
              // this.totals.outstandingAmount = dueAmount // tolerance fix

              this.sale = data;
              this.isSynched = true;
              clearInterval(interval);
              resolve();
            }
            amountChecks += 1;
            if (amountChecks === posConfig.syncAmountTotalChecks) {
              clearInterval(interval);
              reject(new Error('Maximum number of checks reached'));
            }
          } catch (error: any) {
            if (error && error.response.status === 404) {
              return;
            }
            clearInterval(interval);
            reject(error);
          }
        }, posConfig.tasksCheckTimer);
      });
    },
    async createTask(lineId: number | null, status: string, type: any, quantity: any, productId: number | null, payload?: any) {

      const uniqueId = generateUniqueId();
      // Maak een conceptTask aan met initiële waarden
      const task = {
        id: uniqueId,
        type: type,
        lineId: lineId,
        productId: productId,
        quantity: quantity,
        status: status,
        payload: payload
      } as any;

      // Voeg de conceptTask toe aan de taskQueue
      this.taskQueue.push(task);

      return {
        status: 'success',
        id: uniqueId
      }
    },
    async updateTask(taskId: string, updates: Partial<{ lineId: number | null, status: string, type: any, quantity: any, productId: number | null, payload: any }>) {
      // Find the task in the taskQueue
      const task = this.taskQueue.find(t => t.id === taskId);

      if (task) {
        // Update task properties with the values from the updates object
        Object.assign(task, updates);

        return {
          status: 'success',
          task: task
        };
      } else {
        return {
          status: 'error',
          message: 'Task not found'
        };
      }
    },
    async formatProductTemplate(product) {

      console.log(product)
      const productStore = useProductStore();

      // const product = this.lines.find(product => product.id === productId);

      const unitPrice = product.prices.default;
      const quantity = 1;
      const { vatIncluded, vatPercentage, discountPercentage } = product;

      const subtotalExclVat = vatIncluded
        ? unitPrice / (1 + vatPercentage / 100) * quantity
        : unitPrice * quantity;
      const discountExclVat = subtotalExclVat * (discountPercentage / 100);
      const totalExclVat = subtotalExclVat - discountExclVat;
      const totalVat = totalExclVat * (vatPercentage / 100);
      const totalInclVat = totalExclVat + totalVat;

      const template = {
        id: product.id,
        lineId: null,
        image: product.media.find(file => file.media_type === 'image') || null,
        title: product.title,
        price: unitPrice,
        prices: { default: unitPrice, baseInclVat: unitPrice  },
        discountPercentage: discountPercentage,
        quantity: quantity,
        vatPercentage: vatPercentage,
        vatIncluded: vatIncluded,
        isLocked: false,
        isLoading: false,
      }

      const { status, data: productCheck } = await productStore.getProductPrice(product.id, this.sale.customer_id);

      if (status === 'success') {
        template.price = productCheck.discountInclVat;
        // template.prices.default = productCheck.discountInclVat;
        template.discountPercentage = productCheck.discountPercentage;
      }

      await this.calculateLineTotals(template);

      return template;
    }
  }
});